.app {
  position: relative;
  /* Your current app styles... */
}

.language-selector {
  position: absolute;
  top: 10px;
  right: 10px;
}

.language-button {
  /* Style your language buttons as desired */
}

body {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: 0;
  background-color: #f4f4f4;
  font-family: Arial, sans-serif;
}

.container {
  display: flex;
  width: 80%;
  box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
}

.left, .right {
  width: 50%;
  padding: 20px;
}

.right {
  display: flex;
  justify-content: center;
  align-items: center;
}

img {
  max-width: 100%;
}

button {
  margin: 10px 0;
  padding: 10px;
  border: none;
  border-radius: 5px;
  background-color: #007BFF;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #0056b3;
}

